import { lazy, Suspense } from "react";
import MESessionReportSummary from "./components/pages/reports/sessionReports/MESessionReportSummary";
import Page from "./components/templates/Page";
import AuthGuard from "./guards/AuthGuard";
import BecomeGuard from "./guards/BecomeGuard";
import GuestGuard from "./guards/GuestGuard";
import RoleGuard from "./guards/RoleGuard";
import Loading from "components/molecules/Loading";
import { Navigate } from "react-router-dom";
// @ts-ignore
import type { PartialRouteObject } from "react-router";

// eslint-disable-next-line react/display-name
const Loadable = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication, system, and user management
const LoginPage = Loadable(lazy(() => import("./components/pages/LoginPage")));
const Dashboard = Loadable(lazy(() => import("./components/pages/Dashboard")));
const NoAccess = Loadable(
  lazy(() => import("./components/pages/errors/NoAccess"))
);
const NotFound = Loadable(
  lazy(() => import("./components/pages/errors/NotFound"))
);
const Unauthorized = Loadable(
  lazy(() => import("./components/pages/errors/Unauthorized"))
);
const DownForMaintenance = Loadable(
  lazy(() => import("./components/pages/errors/DownForMaintenance"))
);
const MySettings = Loadable(
  lazy(() => import("./components/pages/mySettings/MySettings"))
);
const ResetPassword = Loadable(
  lazy(() => import("./components/pages/resetPassword/ResetPassword"))
);

// unauthenticated
const AccreditationCreateAccount = Loadable(
  lazy(
    () =>
      import("./components/pages/becomeAccredited/AccreditationCreateAccount")
  )
);
const AccreditationContinued = Loadable(
  lazy(
    () =>
      import("./components/pages/accreditationContinued/AccreditationContinued")
  )
);

// Accreditation
const Accreditation = Loadable(
  lazy(() => import("./components/pages/accreditationsList/Accreditation"))
);

// Registrars
const Registrars = Loadable(
  lazy(() => import("./components/pages/Registrars"))
);
const RegistrarDetails = Loadable(
  lazy(() => import("./components/pages/RegistrarDetails"))
);
const RegistrarProgressionSummary = Loadable(
  lazy(() => import("./components/pages/RegistrarProgressionSummary"))
);
const RegistrarCGTTrainingRequirements = Loadable(
  lazy(() => import("./components/pages/RegistrarCGTTrainingRequirements"))
);
const RegistrarCGTEducationRequirements = Loadable(
  lazy(() => import("./components/pages/RegistrarCGTEducationRequirements"))
);
const RegistrarASTRequirements = Loadable(
  lazy(() => import("./components/pages/RegistrarASTRequirements"))
);
const RegistrarCGTAssessmentRequirements = Loadable(
  lazy(() => import("./components/pages/RegistrarCGTAssessmentRequirements"))
);
const RegistrarAssessmentPrepAndSupport = Loadable(
  lazy(() => import("./components/pages/RegistrarAssessmentPrepAndSupport"))
);
const RegistrarPlacementHistory = Loadable(
  lazy(() => import("./components/pages/RegistrarPlacementHistory"))
);
const RegistrarLogbookSummary = Loadable(
  lazy(() => import("./components/pages/RegistrarLogbookSummary"))
);
const RegistrarMESessionDetails = Loadable(
  lazy(() => import("./components/pages/RegistrarMESessionDetails"))
);
const RegistrarMESessionReportsSummary = Loadable(
  lazy(() => import("./components/pages/RegistrarMESessionReportsSummary"))
);
const RegistrarMESessionReportsSummaries = Loadable(
  lazy(() => import("./components/pages/RegistrarMESessionReportsSummaries"))
);
const RegistrarContactHistory = Loadable(
  lazy(() => import("./components/pages/RegistrarContactHistory"))
);
const RegistrarDocuments = Loadable(
  lazy(() => import("./components/pages/RegistrarDocuments"))
);
const RegistrarActionItems = Loadable(
  lazy(() => import("./components/pages/RegistrarActionItems"))
);
const RegistrarLogbookEntries = Loadable(
  lazy(() => import("./components/pages/RegistrarLogbookEntries"))
);

// Session Reports
const SessionReports = Loadable(
  lazy(() => import("./components/pages/reports/sessionReports/SessionReports"))
);
const CreateSessionReport = Loadable(
  lazy(
    () =>
      import("./components/pages/reports/sessionReports/CreateSessionReport")
  )
);
const EditSessionReport = Loadable(
  lazy(
    () => import("./components/pages/reports/sessionReports/EditSessionReport")
  )
);
const MESessionReport = Loadable(
  lazy(
    () =>
      import("./components/pages/reports/sessionReports/MESessionReportSummary")
  )
);
const AddSummaryReport = Loadable(
  lazy(
    () => import("./components/pages/reports/sessionReports/AddSessionSummary")
  )
);
const AddTasks = Loadable(
  lazy(
    () =>
      import("./components/pages/reports/sessionReports/ViewSessionReportTasks")
  )
);

// Organisation
const Organisation = Loadable(
  lazy(() => import("./components/pages/organisation/Organisation"))
);
const OrganisationAddPerson = Loadable(
  lazy(() => import("./components/pages/organisation/AddPerson"))
);
const OrganisationEditPerson = Loadable(
  lazy(() => import("./components/pages/organisation/EditPerson"))
);

// Appointments
const AppointmentsPage = Loadable(
  lazy(() => import("./components/pages/appointments/Appointments"))
);

// Training Post Profile
const TrainingPostProfile = Loadable(
  lazy(
    () => import("./components/pages/trainingPostProfile/TrainingPostProfile")
  )
);

// Placement Management
const RegistrarPlacementProfile = Loadable(
  lazy(
    () =>
      import("./components/pages/placementManagement/RegistrarPlacementProfile")
  )
);

const PlacementCapacityCollection = Loadable(
  lazy(
    () =>
      import(
        "./components/pages/placementCapacityCollection/PlacementCapacityCollection"
      )
  )
);

const RegistrarOffers = Loadable(
  lazy(() => import("./components/pages/placementManagement/RegistrarOffers"))
);

// Reports
const CoreGeneralistTrainingReportPage = Loadable(
  lazy(() => import("./components/pages/reports/CGTReportList"))
);
const CoreGeneralistTrainingReportDetails = Loadable(
  lazy(() => import("./components/pages/reports/CGTReportDetails"))
);

// Payments Receipts
const PaymentsReceipts = Loadable(
  lazy(() => import("./components/pages/paymentsReceipts/paymentsReceipts"))
);
const PaymentsReceiptDetails = Loadable(
  lazy(
    () => import("./components/pages/paymentsReceipts/paymentsReceiptDetails")
  )
);

// Training Posts
const TrainingPosts = Loadable(
  lazy(() => import("./components/pages/trainingPosts/list"))
);
const TrainingPostDetails = Loadable(
  lazy(() => import("./components/pages/trainingPosts/details"))
);

// Tasks
const Tasks = Loadable(lazy(() => import("./components/pages/Tasks")));

// Employee Agreements
const EmployeeAgreements = Loadable(
  lazy(() => import("./components/pages/employeeAgreements/EmployeeAgreements"))
);
const EmployeeAgreementUpload = Loadable(
  lazy(
    () =>
      import("./components/pages/employeeAgreements/EmployeeAgreementUpload")
  )
);

const routes: PartialRouteObject[] = [
  {
    path: "/",
    element: <Navigate to="/dashboard" replace />
  },
  {
    path: "index",
    element: <Navigate to="/dashboard" replace />
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Dashboard">
          <Page currentPath={"/dashboard"}>
            <Dashboard />
          </Page>
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "tasks",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="My Tasks">
          <Page currentPath={"/tasks"}>
            <Tasks />
          </Page>
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <LoginPage />
      </GuestGuard>
    )
  },
  {
    path: "become-accredited",
    element: (
      <BecomeGuard>
        <AccreditationCreateAccount />
      </BecomeGuard>
    )
  },
  {
    path: "accreditation",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Accreditation">
          <Page currentPath="accreditation">
            <Accreditation />
          </Page>
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "registrars",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Registrars">
          <Registrars />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "reports/session-reports",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Reports|Session Reports">
          <SessionReports />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "reports/session-reports/create",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Reports|Session Reports">
          <CreateSessionReport />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "reports/session-reports/edit",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Reports|Session Reports">
          <EditSessionReport />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "reports/session-reports/viewreport",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Reports|Session Reports">
          <MESessionReport />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "reports/session-reports/me-session-report",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Reports|Session Reports">
          <MESessionReportSummary />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "reports/session-reports/create/add-session-summary",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Reports|Session Reports">
          <AddSummaryReport />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "reports/session-reports/create/add-session-summary/add-tasks",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Reports|Session Reports">
          <AddTasks />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "accreditation/:id",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Accreditation">
          <AccreditationContinued />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "registrars/:id",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Registrars">
          <RegistrarDetails />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "registrars/:id/progression",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Registrars">
          <RegistrarProgressionSummary />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "registrars/:id/progression/cgt-training",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Registrars">
          <RegistrarCGTTrainingRequirements />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "registrars/:id/progression/cgt-education",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Registrars">
          <RegistrarCGTEducationRequirements />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "registrars/:id/progression/ast-requirements",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Registrars">
          <RegistrarASTRequirements />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "registrars/:id/progression/cgt-assessment",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Registrars">
          <RegistrarCGTAssessmentRequirements />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "registrars/:id/progression/assessment-prep-support",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Registrars">
          <RegistrarAssessmentPrepAndSupport />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "registrars/:id/progression/placement-history",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Registrars">
          <RegistrarPlacementHistory />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "registrars/:id/progression/logbook-summary",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Registrars">
          <RegistrarLogbookSummary />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "registrars/:id/progression/me-session-details",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Registrars">
          <RegistrarMESessionDetails />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "registrars/:id/progression/me-session-details/report",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Registrars">
          <RegistrarMESessionReportsSummary />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "registrars/:id/progression/me-session-details/me-session-reports-summaries",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Registrars">
          <RegistrarMESessionReportsSummaries />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "registrars/:id/progression/contact-history",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Registrars">
          <RegistrarContactHistory />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "registrars/:id/progression/logbook-summary/logbook-entries",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Registrars">
          <RegistrarLogbookEntries />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "registrars/:id/progression/documents",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Registrars">
          <RegistrarDocuments />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "registrars/:id/progression/action-items",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Registrars">
          <RegistrarActionItems />
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "training-post/organisation",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Training Post|Organisation">
          <Page currentPath="training-post/organisation">
            <Organisation />
          </Page>
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "training-post/organisation/add-person",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Training Post|Organisation">
          <Page currentPath="training-post/organisation">
            <OrganisationAddPerson />
          </Page>
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "training-post/organisation/person/:id",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Training Post|Organisation">
          <Page currentPath="training-post/organisation">
            <OrganisationEditPerson />
          </Page>
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "training-post/training-post-profile",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Training Post|Training Post Profile">
          <Page currentPath="training-post/training-post-profile">
            <TrainingPostProfile />
          </Page>
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "training-post/capacity-collection",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Training Post|Capacity Collection">
          <Page currentPath="training-post/capacity-collection">
            <PlacementCapacityCollection />
          </Page>
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "training-post/employee-agreements",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Training Post|Employee Agreements">
          <Page currentPath="training-post/employee-agreements">
            <EmployeeAgreements />
          </Page>
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "training-post/employee-agreements/upload/:registrarId/:practiceId/:registrarName/:pathway/:employeeAgreementId",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Training Post|Employee Agreements">
          <Page currentPath="training-post/employee-agreements">
            <EmployeeAgreementUpload />
          </Page>
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "appointments",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Appointments">
          <Page currentPath="/appointments">
            <AppointmentsPage />
          </Page>
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "placement-management/registrar-preferencing",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Placement Management">
          <Page currentPath="/placement-management/registrar-preferencing">
            <RegistrarPlacementProfile />
          </Page>
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "placement-management/registrar-offers",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Placement Management">
          <Page currentPath="/placement-management/registrar-offers">
            <RegistrarOffers />
          </Page>
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "reports/core-generalist-training-reports",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Reports|Core Generalist Training Reports">
          <Page currentPath="/reports/core-generalist-training-reports">
            <CoreGeneralistTrainingReportPage />
          </Page>
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "reports/core-generalist-training-reports/:reportId",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Reports|Core Generalist Training Reports">
          <Page currentPath="/reports/core-generalist-training-reports">
            <CoreGeneralistTrainingReportDetails />
          </Page>
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "training-posts",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Training Posts">
          <Page currentPath="/training-posts">
            <TrainingPosts />
          </Page>
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "training-posts/:id",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Training Posts">
          <Page currentPath="/training-posts">
            <TrainingPostDetails />
          </Page>
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "payments-receipts",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Payments Receipts">
          <Page currentPath="/paymentsReceipts">
            <PaymentsReceipts />
          </Page>
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "payments-receipts-details",
    element: (
      <AuthGuard>
        <RoleGuard moduleName="Payments Receipts">
          <Page currentPath="/paymentsReceipts">
            <PaymentsReceiptDetails />
          </Page>
        </RoleGuard>
      </AuthGuard>
    )
  },
  {
    path: "my-settings",
    element: (
      <AuthGuard>
        <Page currentPath={"/my-settings"}>
          <MySettings />
        </Page>
      </AuthGuard>
    )
  },
  {
    path: "reset-password",
    element: <ResetPassword />
  },
  {
    path: "/no-access",
    element: <NoAccess />
  },
  {
    path: "/unauthorized",
    element: <Unauthorized />
  },
  {
    path: "/down-for-maintenance",
    element: <DownForMaintenance />
  },
  {
    path: "*",
    element: <NotFound />
  }
];

export default routes;
